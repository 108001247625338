.popup {
    background-color: #f8fafc;
}

.close {
    float: right;
}

.popup-content {
    font-size: 18px;
    margin-bottom: 10px;
}

.separator {
    border-color: #e2e8f0;
    margin: 10px 0;
}

.companySelectWrapper {
    margin-bottom: 15px;
    width: 100%;
}

.companyInput {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #e5eaf2;
    outline: none;
    box-sizing: border-box;
}

.ruleDescription {
    font-size: 16px;
    margin-bottom: 5px;
    resize: none;
}

.descriptionTextarea {
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    border: 1px solid #e5eaf2;
    outline: none;
    box-sizing: border-box;
    resize: none;
}

.actionButtons {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.cancelButton {
    margin-right: 10px;
    padding: 8px 20px;
    border-radius: 1px;
    background-color: #ffffff;
    cursor: pointer;
    border: 2px solid #007bff;
    color: #007bff;
    font-weight: bold;
}

.saveButton {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 7em;
}

.error {
    color: #f43f5e;
}

.errorBorder {
    border-color: #f43f5e !important;
}

.popupContent {
    font-weight: bold;
    background-color: #021d3a;
}
.refundButton {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: auto;
}
.externalIdInput {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.amountInput {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.refundNotes {
    font-size: medium;
}

.rejectLabel {
    padding: 2rem;
}
.warningSection {
    max-width: 80%;
    margin-left: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 6px;
    display: flex;
}

.rejectButton {
    background-color: #f43f5e;
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    width: auto;
    height: 3em;
}

.refundHeaderText {
    padding: 0.5em;
}

.refundBodyText {
    padding: 0.5em;
    background-color: #e7f1fb;
    border-radius: 7px;
    padding: 0.5em;
    margin: 0.5em;
}
