.posTransactionsWrapper {
    margin-top: 15px;
    width: 100%;
}

.tableHeader {
    text-align: left;
    color: white;
    padding: 7px 0px 7px 10px;
    margin: 0;
    background-color: var(--navbar-color);
    border-radius: 3px 3px 0px 0px;
    width: 100%;
    display: flex;
    align-items: center;
}

.tableBodyWrapper {
    flex-grow: 1;
    height: calc(100vh - 38em);
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.emptyData {
    color: #b0b0b0;
    text-align: center;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 55%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    top: 200px;
}

.posTransactionsData {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 20px;
    position: relative;
    border-radius: 3px;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.posTransactionsFilters {
    z-index: 1;
    position: relative;
}

.logCount {
    margin-left: 0.5em;
    margin-right: 4em;
}

/* Define base classes for row colors with hover effects */
.row-success {
  background-color: #efffef; /* Very light green */
}
.row-success:hover {
  background-color: #c8ffc8; /* Slightly darker green on hover */
}

.row-failed {
  background-color: #fffafa; /* Very light red */
}
.row-failed:hover {
  background-color: #fff7f7; /* Slightly darker red on hover */
}

.row-pending {
  background-color: #eff8ff; /* Very light blue */
}
.row-pending:hover {
  background-color: #c8e6ff; /* Slightly darker blue on hover */
}
