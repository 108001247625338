.chargebackAlertsWrapper {
    margin-top: 15px;
    width: 100%;
}

.tableHeader {
    color: white;
    padding: 7px 0px 7px 10px;
    margin: 0;
    background-color: var(--navbar-color);
    border-radius: 3px 3px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleDownload {
    display: flex;
    justify-content: space-between;
}

.tableBodyWrapper {
    flex-grow: 1;
    height: calc(100vh - 32em);
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.emptyData {
    color: #b0b0b0;
    text-align: center;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 55%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    top: 200px;
}

.chargebackAlertsData {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 20px;
    position: relative;
    border-radius: 3px;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.chargebackAlertsFilters {
    z-index: 1;
    position: relative;
}

.viewRulesButton {
    margin-right: 1em;
    color: black;
    border-color: black;
    border-radius: 2em;
    height: 2em;
    width: 10em;
    padding: 0px;
    font-weight: bold;
    font-size: 16px;
    border-style: solid;
}

.refundButton {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    background-color: #033162;
    color: #fff;
    cursor: pointer;
    width: 10em;
}

.rejectButton {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    background-color: #b41702f5;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
    width: 10em;
}

.refundButton:disabled,
.rejectButton:disabled {
    background-color: #d3d3d3;
    color: #000000;
    cursor: not-allowed;
}
