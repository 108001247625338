.chargebackPreventionRulesWrapper {
    padding: 1em;
}

.header {
    font-size: 15px;
    color: white;
    width: 100%;
    padding: 0.75em 0.5em;
    font-weight: bold;
    background-color: #283048;
}

.merchants {
    color: black;
    width: 20em;
    min-height: 5px;
    margin-bottom: 1em;
}

.bodyLoader {
    text-align: center;
}

.emptyRules {
    text-align: center;
    font-weight: bold;
    padding: 0.5em;
    display: block;
}

.bodyWrapper {
    background-color: #235669;
}

.popup {
    padding: 24px;
  }
  
  .popupContent {
    font-weight: bold;
  }
  
  .separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }
  
  .actionButtons {
    display: flex;
    justify-content: space-between; 
    margin-top: 1.5em;
  }
  
  .cancelButton,
  .popupDeleteButton {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    width: 10rem;
  }
  
  .cancelButton {
    background-color: #fff;
    color: #0365F4;
    border: 2px solid #0365F4;
  }
  
  .modalDeleteButton {
    background-color: #F43F5E;
    color: #fff;
    border: none;
    width: 9rem
  }

  .headerAction{
    display: flex;
    justify-content: space-between;
  }


  .actionButton{
    background-color: #0365F4;
    color: #fff;
    width: 11em;
    min-height: 5px;
    margin-bottom: 1em;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 8em;
  }
  
  .tableRow {
    background-color: white;
}

.tableRow td {
    padding: 8px;
}

.editButton, .deleteButton {
    cursor: pointer;
    text-decoration: underline;
}

.editButton {
    margin-right: 10px;
}

.popupDeleteButton {
    color: #F43F5E;
    text-decoration: underline;
}