.consentBannerWrapper {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    padding: 10px;
    border-top: 1px solid #ccc;
    text-align: center;
    z-index: 1000;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    left: 0;
}

.consentBannerInner {
    color: white;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
    position: fixed;
    overflow: auto;
    bottom: 0;
    left: 0;
    background-color: #022128;
    padding: 50px 20px;
    font-size: 16px;
    text-align: left;
}

.consentText {
    display: inline-block;
    margin-bottom: 1em;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
}
