
.popup {
    padding: 20px;
    background-color: #F8FAFC;
  }
  
  .close {
    float: right;
  }
  
  .popup-content {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .separator {
    border-color: #E2E8F0;
    margin: 10px 0;
  }
  
  .companySelectWrapper {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .companyInput {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #E5EAF2;
    outline: none;
    box-sizing: border-box;
  }
  
  .ruleDescription {
    font-size: 16px;
    margin-bottom: 5px;
    resize: none;
  }
  
  .descriptionTextarea {
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    border: 1px solid #E5EAF2;
    outline: none;
    box-sizing: border-box;
    resize: none;
  }
  
  .actionButtons {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .cancelButton {
    margin-right: 10px;
    padding: 8px 20px;
    border-radius: 1px;
    background-color: #ffffff;
    cursor: pointer;
    border: 2px solid #007bff;
    color: #007bff;
    font-weight: bold;
  }
  
  .saveButton {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 7em;
  }

  .error {
    color: #F43F5E;
  }
  
  .errorBorder {
    border-color: #F43F5E !important;
  }

  .popupContent{
    font-weight: bold;
  }