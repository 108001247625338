body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.charts-div {
  display: flex;
  flex-wrap: wrap;
}

.filter-chips {
  margin: 10px;
}

.box-Width {
  width: auto;
}

/*.chart-innerDiv {
  margin: 20px;
}*/
/*.recharts-wrapper,
.recharts-surface {
  /*width: 100% !important;}*/

.recharts-legend-wrapper {
  width: 100% !important;
}

.shift-right {
  text-align: right;
}

.select-resize {
  height: 26px;
  padding: 4px 12px;
}

.color-red {
  color: red;
}
.align-center {
  text-align: center;
}

.box-border {
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
}
.form-box {
  margin: 0 px;
  background-color: white;
}
.padd-20 {
  padding: 20px;
}
.padd-20 input {
  width: 100%;
}
.margin-0 {
  margin: 0px !important;
}

.marginTop-5 {
  margin-top: 5px !important;
}

.bg-transparent {
  background: transparent;
}
.border-right {
  border-right: 1px solid #9c9e9e;
}
.bg-color {
  background: #9c9e9e;
}
.custom-btn {
  border: 1px solid red;
  color: red;
  background: #fff;
  /* margin: 5px; */
}

.bg-color-red {
  background: red;
  color: black;
}

.cursor {
  cursor: pointer;
}

.circle-icon {
  color: #283048;
  background: #55bd8b;
  padding: 10px 11px 10px 11px;
  border-radius: 6px;
  margin-left: 6px;
}

/*React DatePicker Component*/

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

/* Side bar navigation*/

/* .skin-blue .sidebar-menu > li:hover > a{
  color: #D9655F;
} */
.skin-blue .sidebar-menu > li.active > a {
  color: #5f98d9;
}
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: #55bd8b;
}

.sidebar-menu > li:hover > a,
.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li.menu-open > a {
  color: #ffffff;
  background-color: #000;
}

.skin-blue .sidebar-menu > li:hover > a {
  background: #101820;
}

/*React dasboard-2 Component*/
.b-padding {
  padding-left: 12px;
}

.box-width {
  margin-left: 15px;
  background: #818d97;
  padding-bottom: 1px;
}
.box-heading {
  margin: 0;
  padding: 10px 10px 10px 10px;
  background: #283048;
  text-align: left;
  border-radius: 3px 3px 0px 0px;
}
/* .recharts-wrapper{
  background: 
} */

.box1 {
  border-top: 0;
}

.box-heading1 {
  margin: 0;
  text-align: center;
}

.chart-heading1 {
  color: black;
  position: absolute;
  margin-left: -5%;
  margin-top: 1%;
}

.box-new .recharts-wrapper {
  background: #424752;
}
.box-width .react-bootstrap-daterangepicker-container button {
  background: #e2e7eb;
  border-color: #566072;
  margin-left: 12px;
  margin-top: 10px;
}
caption h3 {
  margin: 0;
}
.table-row {
  margin: 0;
}

.box-align {
  margin-top: -10px;
  margin-left: 10px;
}
.box-body-padding {
  padding-bottom: 0;
}
/* .box .react-bootstrap-table .table-border{
  border: none;
  
} */
.nav-tabs > li.active > a {
  background-color: #fff;
}
.nav-tabs > li > a,
.nav > li > a:hover {
  background-color: #283048;
  color: #fff;
}
/*#myTab li:last-child {
  width: 27%;
}*/
#myTab li:last-child a {
  margin-right: 0;
}

@media (min-width: 768px) {
  .b-col {
    float: right;
  }
}

/**
    TableChart component
*/

.tab-pagination-container {
  margin-left: 0px;
  margin-top: -20px;
  margin-right: 0px;
}

.tab-pagination {
  margin-left: 5px;
  margin-right: 5px;
}

.table-bordered_ th,
.table-bordered_ td {
  border: 1px solid #2e80b2 !important;
}

.row-bottom-margin {
  margin: 0px !important;
}

.row-margin-top {
  margin-top: 50px;
}

.table-bordered_extended td {
  border: 1px solid white !important;
}

.fixed-height-box {
  height: 465px;
  /* height: 480px; */
}

.margin-auto {
  margin-left: auto;
}

/* .nested-table-box {
  height: 530px;
} */
.pagination.react-bootstrap-table-page-btns-ul {
  position: absolute;
  bottom: 0px;
}

.pagination.react-bootstrap-table-page-btns-ul {
  margin-bottom: 20px;
}

.react-bootstrap-table tr:nth-child(odd) td {
  background: #f9f9f9;
}

.stripped tr:nth-child(odd) td {
  background: #f9f9f9 !important;
}

.quick-filters-left {
  text-align: left;
}
.quick-filters-right {
  text-align: right;
}

.quick-filters .form-group {
  display: inline-block;
}

@media screen and (max-width: 500px) {
  .quick-filters-left,
  .quick-filters-right {
    text-align: center;
  }
}

.bg-red {
  background-color: rgb(243, 23, 23) !important;
}
.bg-orange {
  background-color: #ff8c00 !important;
}
.bg-yellow {
  background-color: rgb(235, 245, 100) !important;
  color: rgb(0, 0, 0) !important;
}

.virTermForm {
  width: -webkit-fill-available;
}

.select-custom-parent {
  background: #35a7e3;
  padding: 0;
  border: solid 1px #35a7e3;
  margin-left: 13px;
}

.select-custom {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: white;
}

.checkbox-paylink {
  display: flex;
  align-items: flex-start;
}
